define('twapi/models/kba-questionnaire', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      hasMany = _emberRestless.default.hasMany;
  exports.default = Model.extend({
    questions: hasMany('kba-question')
  }).reopenClass({
    resourceName: 'kba-questionnaire'
  });
});