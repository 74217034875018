define('twapi/models/kba', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var bool = Ember.computed.bool;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend({

    authenticationId: attr('string'),

    questionnaire: belongsTo('kba-questionnaire'),

    hasQuestionnaire: bool('questionnaire')
  }).reopenClass({
    resourceName: 'kba'
  });
});