define('account-opening/components/step/security-equifax', ['exports', 'ember-decorators/object', 'twapi/utils/validation', 'twapi/utils/restless'], function (exports, _object, _validation, _restless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool,
      not = _Ember$computed.not,
      service = Ember.inject.service,
      get = Ember.get,
      isPresent = Ember.isPresent;
  exports.default = Component.extend((_dec = (0, _object.computed)('equifax', 'equifax.existingResult'), _dec2 = (0, _object.computed)('questions.@each.isAnswered'), (_obj = {
    equifax: service(),

    classNames: 'step-security-equifax',

    hasPendingOrApprovedApplication: alias('equifax.hasPendingOrApprovedApplication'),

    equifaxConfirmed: not('hasPendingOrApprovedApplication'),

    questions: alias('equifax.questions'),

    hasQuestionnaire: alias('equifax.hasQuestionnaire'),

    isAccepted: bool('kycResult.isAccepted'),

    isSubmitted: bool('equifax.isQuestionnaireSubmitted'),

    kycResult: function kycResult(equifax, existingResult) {
      if (isPresent(existingResult)) {
        return existingResult;
      }
      _restless.default.asPromise(equifax.fetchKycResult()).then(function (result) {
        return result;
      });
    },
    allQuestionsAnswered: function allQuestionsAnswered(questions) {
      return questions.isEvery('isAnswered');
    },


    actions: {
      submit: function submit() {
        get(this, 'equifax').submitKbaQuestionnaire();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'kycResult', [_dec], Object.getOwnPropertyDescriptor(_obj, 'kycResult'), _obj), _applyDecoratedDescriptor(_obj, 'allQuestionsAnswered', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'allQuestionsAnswered'), _obj)), _obj))).reopen(_validation.build({
    equifaxConfirmed: { presence: true }
  }));
});