define('twapi/models/kba-question', ['exports', 'lodash', 'ember-restless', 'ember-decorators/object', 'twapi/utils/validation'], function (exports, _lodash, _emberRestless, _object, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool,
      get = Ember.get,
      set = Ember.set;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany;
  exports.default = Model.extend((_dec = (0, _object.computed)('choices.[]'), (_obj = {
    questionText: attr('string', { readOnly: true }),
    questionId: attr('number'),
    choices: hasMany('kba-choice', { readOnly: true }),
    selectedChoiceId: attr('number'),
    answer: alias('selectedChoiceId'),
    isAnswered: bool('selectedChoiceId'),

    options: function options(choices) {
      return choices.map(function (choice) {
        return {
          value: get(choice, 'choiceId'),
          text: get(choice, 'choiceText')
        };
      });
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);
      set(serialized, 'answer', get(this, 'answer'));

      return _lodash.default.omit(serialized, 'selected-choice-id');
    }
  }, (_applyDecoratedDescriptor(_obj, 'options', [_dec], Object.getOwnPropertyDescriptor(_obj, 'options'), _obj)), _obj))).reopen(_validation.build({
    selectedChoiceId: { presence: true }
  }));
});